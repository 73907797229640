import { Form, Field } from "react-final-form";
import { FormattedMessage } from "@/i18n/i18n-client";
import { PrimaryButton } from "@/components/Button";
import { RequiredSpan } from "../Contact/styled";
import type {
  ResetPasswordFormProps,
  ResetPasswordFormValues,
  TemplateElement,
} from "../../../types";
import Flex from "@/shared/globals/UiElements/Flex";
import InputWithIcon from "@/shared/globals/UiElements/InputWithIcon";
import { Lock } from "@/assets/Icons/Lock";

const ResetPasswordForm: TemplateElement<ResetPasswordFormProps> = ({
  onSubmit,
  focusOnError,
}) => {
  return (
    <Form<ResetPasswordFormValues>
      onSubmit={onSubmit}
      validate={validate}
      decorators={[focusOnError as any]}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Flex column spacing="xl">
              <Flex
                column
                alignItems="center"
                justifyContent="center"
                spacing="s"
              >
                <p className="m-0 text-gray-900 font-semibold text-xl">
                  <FormattedMessage
                    defaultMessage="Enter your new password"
                    id="h/zqNr"
                  />
                </p>
              </Flex>
              <label className="w-full text-gray-600 text-sm font-medium">
                <FormattedMessage defaultMessage="New Password" id="Ev6SEF" />
                <Field name="newPassword">
                  {({ input, meta: { error, touched } }) => (
                    <div className="mt-1.5">
                      <InputWithIcon
                        {...input}
                        type="password"
                        prefix={<Lock />}
                        className={error && touched ? "invalid" : ""}
                        placeholder="••••••••"
                      />
                      {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                    </div>
                  )}
                </Field>
              </label>
              <label className="w-full text-gray-600 text-sm font-medium">
                <FormattedMessage
                  defaultMessage="Confirm Password"
                  id="vfG+nh"
                />
                <Field name="confirmPassword">
                  {({ input, meta: { error, touched } }) => (
                    <div className="mt-1.5">
                      <InputWithIcon
                        {...input}
                        type="password"
                        prefix={<Lock />}
                        className={error && touched ? "invalid" : ""}
                        placeholder="••••••••"
                      />
                      {error && touched && <RequiredSpan>{error}</RequiredSpan>}
                    </div>
                  )}
                </Field>
              </label>
              <PrimaryButton type="submit" fullWidth isLoading={submitting}>
                <FormattedMessage defaultMessage="Reset Password" id="xl27nc" />
              </PrimaryButton>
            </Flex>
          </form>
        );
      }}
    />
  );
};

export default ResetPasswordForm;

/**
 *
 * Functions
 *
 */

const validate = (values: ResetPasswordFormValues) => {
  const errors: Record<string, unknown> = {};

  if (!values?.newPassword?.trim() || values?.newPassword?.length < 6) {
    errors.newPassword = (
      <FormattedMessage
        defaultMessage="Password must contains at least 6 characters"
        id="lCB5UQ"
      />
    );
  }

  if (
    !values?.confirmPassword?.trim() ||
    values?.confirmPassword !== values?.newPassword
  ) {
    errors.confirmPassword = (
      <FormattedMessage defaultMessage="Passwords doesn't match!" id="iJmetL" />
    );
  }

  return errors;
};
