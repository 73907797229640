import LoginForm from "@/templates/default/elements/Authentication/LoginForm";
import RegistrationForm from "@/templates/default/elements/Authentication/RegistrationForm";
import ResetPasswordForm from "@/templates/default/elements/Authentication/ResetPasswordForm";
import ForgotPasswordForm from "@/templates/default/elements/Authentication/ForgotPasswordForm";
import { StoreTemplate } from "@/templates/TemplateLoader";
import { ReactNode } from "react";

const elements = {
  LoginForm,
  RegistrationForm,
  ResetPasswordForm,
  ForgotPasswordForm,
};

export function AuthPagesLoader(props: { children: ReactNode }) {
  StoreTemplate.load("farah-omar", "auth", elements);
  return props.children;
}
