import React from "react";
import { Form, Field } from "react-final-form";
import { FormattedMessage, useIntl } from "@/i18n/i18n-client";
import { ExplanationIcon } from "@/assets/Icons/ExplanationIcon";
import { H1 } from "@/shared/globals";
import { PrimaryButton } from "@/components/Button";
import { useStore } from "@/lib/storeData/StoreContext";
import InputPhone, { PhoneInput } from "@/shared/globals/UiElements/InputPhone";
import {
  RegistrationFormProps,
  RegistrationFormValues,
  TemplateElement,
} from "../../../types";
import Flex from "@/shared/globals/UiElements/Flex";
import InputWithIcon from "@/shared/globals/UiElements/InputWithIcon";
import { RequiredSpan } from "../../../default/elements/Contact/styled";
import { Link } from "@/i18n/i18n-navigation";

const RegistrationForm: TemplateElement<RegistrationFormProps> = ({
  errorMessage,
  policies,
  onSubmit,
  focusOnError,
}) => {
  const intl = useIntl();
  const { name: storeName } = useStore();
  return (
    <Form<RegistrationFormValues>
      onSubmit={onSubmit}
      validate={validate}
      decorators={[focusOnError]}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Flex column spacing="xl">
              <Flex
                column
                alignItems="center"
                justifyContent="center"
                spacing="s"
              >
                <H1 fontWeight={600}>
                  <FormattedMessage
                    defaultMessage="Create an account"
                    id="0vL5u1"
                  />
                </H1>
                <p className="text-sm font-normal text-center m-0">
                  <FormattedMessage
                    defaultMessage="Welcome to The {storeName} Store!"
                    id="DVhZ18"
                    values={{ storeName }}
                  />
                  <br />
                  <FormattedMessage
                    defaultMessage="Please fill out the form to become a member."
                    id="l7a1N5"
                  />
                </p>
              </Flex>
              <Flex column>
                <label className="text-sm font-medium">
                  <FormattedMessage defaultMessage="Name" id="HAlOn1" />
                  <Field name="name">
                    {({ input, meta: { error, touched } }) => (
                      <div className="mt-1.5">
                        <InputWithIcon
                          {...input}
                          autoComplete="username"
                          className={error && touched ? "invalid" : ""}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter your name",
                            id: "DX9QSt",
                          })}
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                      </div>
                    )}
                  </Field>
                </label>
                <label className=" text-sm font-medium">
                  <FormattedMessage defaultMessage="Email" id="sy+pv5" />
                  <Field name="email">
                    {({ input, meta: { error, touched } }) => (
                      <div className="mt-1.5">
                        <InputWithIcon
                          {...input}
                          type="email"
                          autoComplete="email"
                          className={error && touched ? "invalid" : ""}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter your email",
                            id: "5MDGuM",
                          })}
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                      </div>
                    )}
                  </Field>
                </label>
                <label className=" text-sm font-medium">
                  <FormattedMessage defaultMessage="Password" id="5sg7KC" />
                  <Field name="password">
                    {({ input, meta: { error, touched } }) => (
                      <div className="mt-1.5">
                        <InputWithIcon
                          {...input}
                          type="password"
                          autoComplete="new-password"
                          className={error && touched ? "invalid" : ""}
                          placeholder="••••••••"
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                      </div>
                    )}
                  </Field>
                </label>
                <label className=" text-sm font-medium">
                  <FormattedMessage defaultMessage="Phone no." id="1SphRN" />
                  <Field<PhoneInput> name="phone">
                    {(fieldProps) => <InputPhone {...fieldProps} />}
                  </Field>
                </label>
                {errorMessage && <RequiredSpan>{errorMessage}</RequiredSpan>}
              </Flex>

              <Flex column>
                <PrimaryButton type="submit" fullWidth isLoading={submitting}>
                  <FormattedMessage
                    defaultMessage="CREATE ACCOUNT"
                    id="DlOmlM"
                  />
                </PrimaryButton>

                <Flex alignItems="center">
                  <Flex
                    width="30px"
                    height="30px"
                    className="bg-black/20 rounded-full"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ExplanationIcon className="w-5" />
                  </Flex>
                  <Flex column spacing="none" alignItems="start">
                    <span>
                      <FormattedMessage
                        defaultMessage="By creating an account you agree to our"
                        id="IUMtOg"
                      />
                    </span>
                    <span className="font-bold">{policies}</span>
                  </Flex>
                </Flex>
              </Flex>
              <hr />
              <Flex justifyContent="center" spacing="xs" column>
                <div className=" text-sm text-center">
                  <FormattedMessage
                    defaultMessage="Already have an Account?"
                    id="2Ksicu"
                  />
                </div>
                <PrimaryButton compact reversed>
                  <Link href="/login">
                    <FormattedMessage defaultMessage="Login" id="AyGauy" />
                  </Link>
                </PrimaryButton>
              </Flex>
            </Flex>
          </form>
        );
      }}
    />
  );
};

export default RegistrationForm;

/**
 *
 * Functions
 *
 */

const validate = (values: RegistrationFormValues) => {
  const errors: Record<string, unknown> = {};
  if (!values?.name?.trim()) {
    errors.name = (
      <FormattedMessage defaultMessage="Your name is required" id="1KxtyA" />
    );
  }
  if (values.name?.trim()?.split(" ")?.length < 2) {
    errors.name = (
      <FormattedMessage defaultMessage="Enter your full name" id="xIDmuE" />
    );
  }

  if (
    !values?.email?.trim() ||
    !values?.email?.match(
      /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    )
  ) {
    errors.email = (
      <FormattedMessage
        defaultMessage="Please enter a valid email address"
        id="oLpv29"
      />
    );
  }

  if (!values?.password?.trim() || values?.password?.length < 6) {
    errors.password = (
      <FormattedMessage
        defaultMessage="Password must contains at least 6 characters"
        id="lCB5UQ"
      />
    );
  }

  if (!values?.phone?.isValid) {
    errors.phone = (
      <FormattedMessage
        defaultMessage="Please enter a valid phone number"
        id="FPcbu0"
      />
    );
  }
  return errors;
};
