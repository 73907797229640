import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { FormattedMessage, useIntl } from "@/i18n/i18n-client";
import { AddUser } from "@/assets/Icons/AddUser";
import { UserFilledIcon } from "@/assets/Icons/UserFilledIcon";
import { FlexRow, P, FlexCol } from "@/shared/globals";
import { PrimaryButton } from "@/components/Button";
import { convertHexToRgba, themeColor } from "@/shared/styles-utils";
import {
  ForgotPasswordFormProps,
  ForgotPasswordFormValues,
  TemplateElement,
} from "../../../types";
import Flex from "@/shared/globals/UiElements/Flex";
import InputWithIcon from "@/shared/globals/UiElements/InputWithIcon";
import { RequiredSpan } from "../../../black-and-white/elements/Contact/styled";
import { Link } from "@/i18n/i18n-navigation";

const ForgotPasswordForm: TemplateElement<ForgotPasswordFormProps> = ({
  isInvalid,
  isSubmitted,
  onSubmit,
  focusOnError,
}) => {
  const intl = useIntl();
  return (
    <Form<ForgotPasswordFormValues>
      onSubmit={onSubmit}
      validate={validate}
      decorators={[focusOnError]}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Flex column spacing="xl">
              <Flex column alignItems="center" justifyContent="center">
                <p className="m-0 text-2xl text-gray-900 font-semibold">
                  <FormattedMessage
                    defaultMessage="Forgot password?"
                    id="V/JHlm"
                  />
                </p>
                <p className=" text-sm font-normal text-center m-0">
                  <FormattedMessage
                    defaultMessage="Don’t worry! Enter your email address."
                    id="M83OHF"
                  />
                  <br />
                  <FormattedMessage
                    defaultMessage="We’ll send you password reset link."
                    id="H51ADl"
                  />
                </p>
              </Flex>
              <Flex column spacing="none">
                <label className="text-gray-700 text-sm font-medium">
                  <FormattedMessage defaultMessage="Email" id="sy+pv5" />
                  <Field name="email">
                    {({ input, meta: { error, touched } }) => (
                      <div className="mt-1.5">
                        <InputWithIcon
                          {...input}
                          type="email"
                          autoComplete="email"
                          className={error && touched ? "invalid" : ""}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter your email",
                            id: "5MDGuM",
                          })}
                          readOnly={isSubmitted}
                        />
                        {error && touched && (
                          <RequiredSpan>{error}</RequiredSpan>
                        )}
                      </div>
                    )}
                  </Field>
                </label>
                {isInvalid && (
                  <RequiredSpan className="!h-auto">
                    <FormattedMessage
                      defaultMessage="This E-mail doesn't exist!"
                      id="cD49dG"
                    />
                  </RequiredSpan>
                )}
              </Flex>
              {isSubmitted && (
                <SuccessSpan>
                  <FormattedMessage
                    defaultMessage="Reset password link was sent successfully, Check your email inbox"
                    id="uv58V4"
                  />
                </SuccessSpan>
              )}

              <PrimaryButton
                type="submit"
                fullWidth
                disabled={isSubmitted}
                isLoading={submitting}
              >
                <FormattedMessage defaultMessage="Reset Password" id="xl27nc" />
              </PrimaryButton>

              <FlexRow justifyContent="space-around" alignItems="stretch">
                <SwitchWrapper>
                  <WithPrimaryColor>
                    <AddUser />
                  </WithPrimaryColor>
                  <div className="text-gray-600 text-sm">
                    <FormattedMessage
                      defaultMessage="Don't Have an Account?"
                      id="kXiqHd"
                    />
                  </div>
                  <PrimaryButton compact reversed>
                    <Link href="/registration">
                      <StyledSpan>
                        <FormattedMessage
                          defaultMessage="Create Account"
                          id="5JcXdV"
                        />
                      </StyledSpan>
                    </Link>
                  </PrimaryButton>
                </SwitchWrapper>
                <VerticalLine />
                <SwitchWrapper>
                  <WithPrimaryColor>
                    <UserFilledIcon />
                  </WithPrimaryColor>
                  <div className="text-gray-600 text-sm">
                    <FormattedMessage
                      defaultMessage="Remember your password?"
                      id="Dfc9/a"
                    />
                  </div>
                  <PrimaryButton compact reversed>
                    <Link href="/login">
                      <StyledSpan>
                        <FormattedMessage
                          defaultMessage="Login to your account"
                          id="3t2ZWR"
                        />
                      </StyledSpan>
                    </Link>
                  </PrimaryButton>
                </SwitchWrapper>
              </FlexRow>
            </Flex>
          </form>
        );
      }}
    />
  );
};

export default ForgotPasswordForm;

/**
 *
 * Functions
 *
 */

const validate = (values: ForgotPasswordFormValues) => {
  const errors: Record<string, unknown> = {};

  if (
    !values?.email?.trim() ||
    !values?.email?.match(
      /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    )
  ) {
    errors.email = (
      <FormattedMessage
        defaultMessage="Please enter a valid email address"
        id="oLpv29"
      />
    );
  }

  return errors;
};

/**
 *
 * Styles
 *
 */

const SwitchWrapper = styled(FlexCol)`
  text-align: center;
`;

const SuccessSpan = styled(P)`
  background-color: #42ab441a;
  color: #42ab44;
  padding: 10px;
  text-align: center;
  border-radius: 6px;
`;

const StyledSpan = styled.span`
  color: ${themeColor("primary")};
  margin-top: 4px;
  font-weight: 600;
  cursor: pointer;
`;

const WithPrimaryColor = styled.span`
  color: ${themeColor("primary")};
`;

const VerticalLine = styled.div`
  width: 1px;
  background-color: ${({ theme }) => convertHexToRgba(theme.bg.wash, 10)};
`;
